<template>
  <Layout :is-short="isShortInput" :is-nested="isNested">
    <!-- <template
      v-if="row.label"
      v-slot:label
    >
      {{ row.label }}
    </template> -->
    <template v-if="row.extra" v-slot:extra>
      {{ row.extra }}
    </template>
    <template v-if="row.prepend" v-slot:prepend>
      {{ row.prepend }}
    </template>

    <template v-slot:input>
      <div class="flex items-center">
        <div
          class="items-center my-2 mr-1 text-xs text-left text-gray-300 select-none option-label"
        >
          {{ row.label }}
        </div>
        <input
          v-model="privateFloatModel"
          :disabled="isDisabled"
          class="flex-auto h-2 mr-1 bg-gray-800 border-2 border-gray-700 rounded-full focus:bg-gray-700 focus:border-gray-600"
          :class="{ 'opacity-75 cursor-not-allowed': isDisabled }"
          type="range"
          :min="inputMin"
          :max="inputMax"
          :step="inputStep"
        />

        <input
          v-model="privateFloatModel"
          :min="inputMin"
          :max="inputMax"
          :step="inputStep"
          class="flex-auto text-center block w-8 px-0.5 py-1 text-2xs leading-tight text-white bg-gray-600 border-1 border-gray-600 rounded-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 focus:outline-none focus:bg-gray-600 focus:border-gray-900"
        />
      </div>
    </template>
  </Layout>
</template>
<script>
import inputs from './InputMixin'

export default {
  name: 'SliderInput',

  mixins: [inputs],

  watch: {
    row(value) {
      if (this.model !== value.value) this.model = value.value
    }
  }
}
</script>
